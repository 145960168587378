<template>
  <div class="main_box">
    <div class="main_boxtop">
      <p>领取兑换码</p>
    </div>
    <div class="main_boxcenter">
      <div class="commodity">
        <img :src="thumb" alt="">
      </div>
      <div>

        <p v-if="product_name.length>53" class="name">{{ `${product_name.substr(0,53)}...` }}</p>
        <p v-else class="name">{{ product_name }}</p>
        <p class="price">￥{{ price }}</p>
        <button v-show="islog" :disabled="disabled" class="lqbtn" @click="getcode()">领取兑换码</button>

      </div>
    </div>
    <div v-show="islog == false" class="main_boxbottom">
      <div class="linered">
        <img src="../../../assets/image/redline.png" alt="">
        <p class="phoneMsg">请输入手机号</p>
      </div>
      <div class="iptbtn">
        <div class="mipt">
          <p>+86</p>
          <input v-model="phone" type="text" placeholder="请输入手机号">
        </div>
        <div>
          <el-button v-show="islog == false" class="bbtn" type="danger" @click="getcode()">领取兑换码</el-button>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import { productm, getredeem } from '@/api/exchangeCode.js'
import Cookie from 'js-cookie'
export default {
  data() {
    return {
      islog: false,
      product_type: '',
      disabled: false,
      product_name: '',
      thumb: '',
      phone: '',
      price: ''
    }
  },
  created() {
    this.getInfo()
  },
  mounted() {
    if (Cookie.get('uid')) {
      console.log(Cookie.get('uid'))
      this.islog = true
    }
  },
  methods: {
    getcode() {
      this.disabled = true
      var params = {
        id: this.$route.query.id,
        phone: this.phone
      }
      getredeem(params).then(res => {
        if (res.errNo == 0) {
          this.$message({
            message: '领取成功',
            type: 'success'
          })
          this.$router.push({
            path: '/getExchangeCodeSuccess',
            query: {
              id: res.result,
              phone: this.phone
            }
          })
        } else {
          this.disabled = false
          this.$message({
            message: res.result,
            type: 'warning'
          })
        }
      })
    },
    getInfo() {
      var params = {
        id: this.$route.query.id
      }
      productm(params).then(res => {
        this.product_type = res.result.product_type
        this.product_name = res.result.product_name
        // this.product_name = '卢卡斯就断开连接的付款了上岛咖啡速度快JFK了就少得可怜附近开了圣诞节快乐就少得可怜附件是快乐的减肥是快乐的就分厘卡圣诞节考虑时间离开房间流口水的解开了就发生了看到见风使舵考虑就少得可怜警方开始独立房间老师肯定'
        this.thumb = res.result.thumb
        this.price = res.result.price
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.phoneMsg{
    position: absolute;
    left: 44.5%;
    top: -27%;
}
.lqbtn{
   position: absolute;
    bottom: 0;
    width: 250px;
    height: 54px;
    text-align: center!important;
    font-size: 20px;
    background: #ee2e2e;
    color: #fff;
    border-radius: 10px;
    border: none;
}
.bbtn{
    width: 250px;
    height: 54px;
    margin-left: 70px;
    font-size: 20px;

}
.mipt{
    width: 680px;
    height: 54px;
    background:  #FBFBFB;
    display: flex;
    border: 1px solid #E6E6E6;
    border-radius: 10px;
    p{
        width: 113px;
        height: 54px;
        text-align: center;
        line-height: 54px;
        color: #666;
        border-right: 1px solid #d8d8d8;
        font-size: 18px;
    }
    input{
    background: #FBFBFB;
    border: none;
    outline: none;
    padding: 0 0 0 30px;
    font-size: 20px;
    color: #bababa;
    }
}
.iptbtn{
    margin: 60px 0 0 100px;
    display: flex;
}
.linered{
    margin: 80px 0 0 0 ;
    position: relative;
    p{
        font-size: 22px;
        color: #666;

    }
}
.main_boxcenter{
  position: relative;
    margin: 50px 0 0 100px;
    display: flex;
    .name{
        font-size: 22px;
        color: #666;
        width: 635px;
        padding: 0 0 0 30px;
        text-align: left;
    }
    .price{
        color: #666;
        font-size: 26px;
        text-align: left;
        padding: 62px 0 0 30px;
    }
    .lqbtn{
        display: block;
        text-align: left;
        margin: 30px 0 0 30px;
    }
}
.commodity{
    text-align: left;
    img{
        width: 335px;
        height: 239px;
        border-radius: 10px;
    }
}
.main_boxtop{
    p{
        height: 20px;
        line-height: 20px;
        display: inline-block;
        color: #333;
        font-size: 18px;
        font-weight: 600;
        border-left: 3px solid #ee2e2e;
        padding: 0px 0 00px 12px;
        margin: 52px 0 0px 100px;
        text-align: left;
        display: block;
        position: relative;
        // border-bottom: 1px solid #DEDEDE;
    }

}
/deep/ .el-button{
    background: #ee2e2e;
}
.main_box{
    margin: 0 auto;
    margin-top: 80px;
    width: 1200px;
    height: 967px;
    background: #fff;
    overflow: hidden;
}
.head{
    box-shadow: 0 0px 0px 0px !important;
}
</style>
